@import '../../abstracts/variables';

.ce_accordion {
    border: 1px dotted $grayLighter;
    padding: 1em;
    margin-bottom: 1em;
    .toggler {
        h1, h2, h3, h4, h5, h6 {
            &:before {
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                content: '\f054';
                font-family: "Font Awesome 5 Free";
                font-weight: 400;
                padding-right: 1em;
            }
        }
        &.ui-accordion-header-active {
            h1, h2, h3, h4, h5, h6 {
                &:before {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    content: '\f078';
                    font-family: "Font Awesome 5 Free";
                    font-weight: 400;
                }
            }
        }
        &.ui-state-hover {
            cursor: pointer;
        }
    }
}
