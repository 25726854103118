@import './variables';
@import './mixins';

@mixin p-notification($color, $content) {
    padding: 0.3rem 0.5rem;
    border: 1px solid $black;
    color: $color;
    background-color: lighten($color, 50%);
    &:before {
        content: $content;
        @include fontAwesome();
        padding-right: 0.5rem;
        color: $color;
    }
}

@mixin p-error {
    @include p-notification($red, '\f071');
}

@mixin p-success {
    @include p-notification($green, '\f058');
}

@mixin p-info {
    @include p-notification($blueDark, '\f05a');
}

