@import '../../abstracts/variables';

#header {
    .mod_custom_header_buttons {
        grid-area: buttons;
        text-align: right;
        padding: $headerPadding $headerPadding $headerPadding 0;
        height: $headerRowHeight;
        @include media('>tablet') {
            height: $headerRowHeightDesktop;
        }

        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-end;

        a {
            box-sizing: border-box;
            display: block;
            background-color: $offcanvasBackgroundColor;
            transition-property: all;
            transition-duration: $headerTransitionDuration;
            transition-timing-function: $headerTransitionTimingFunction;
            height: $headerRowHeight;
            width: $headerRowHeight;
            @include media('>tablet') {
                height: $headerRowHeightDesktop;
                width: $headerRowHeightDesktop;
            }
            &#show-menu {
                margin-left: 0.5rem;
                @include media('>=fullnavigation') {
                    display: none;
                }
            }
            &#show-privacy-settings {
                margin-left: 0.5rem;
            }
            .fas {
                color: $white;
                width: 100%;
                text-align: center;
                vertical-align: center;
                transition-property: all;
                transition-duration: $headerTransitionDuration;
                transition-timing-function: $headerTransitionTimingFunction;
                font-size: 0.6 * $headerRowHeight;
                line-height: 0.6 * $headerRowHeight;
                padding-top: 0.2 * $headerRowHeight;
                padding-bottom: 0.2 * $headerRowHeight;
                @include media('>tablet') {
                    font-size: 0.6 * $headerRowHeightDesktop;
                    line-height: 0.6 * $headerRowHeightDesktop;
                    padding-top: 0.2 * $headerRowHeightDesktop;
                    padding-bottom: 0.2 * $headerRowHeightDesktop;
                }
            }
            &:hover {
                background-color: $offcanvasBackgroundColorHover;
            }
            &.disabled {
                cursor: not-allowed;
                background-color: $grayLight;
                &:hover {
                    background-color: $grayLight;
                }
            }
        }
    }
    &.shrinked {
        .mod_custom_header_buttons {
            height: $headerRowHeightShrinked;
            a {
                height: $headerRowHeightShrinked;
                width: $headerRowHeightShrinked;
                .fas {
                    font-size: 0.6 * $headerRowHeightShrinked;
                    line-height: 0.6 * $headerRowHeightShrinked;
                    padding-top: 0.2 * $headerRowHeightShrinked;
                    padding-bottom: 0.2 * $headerRowHeightShrinked;
                }
            }
        }
    }
}
