@import '../../abstracts/variables';
@import '../../abstracts/alerts';

%tl_message {
    margin-top: 1em;
}

.tl_info {
    @extend %tl_message;
    @include p-info();
}

.tl_error {
    @extend %tl_message;
    @include p-error();
}

.tl_confirm {
    @extend %tl_message;
    @include p-success();
}
