@import '../../abstracts/variables';

#header {
    .mod_custom_header_social {
        grid-area: social;
        text-align: right;
        padding: $headerPadding $headerPadding $headerPadding 0;
        height: $headerRowHeight;
        @include media('>tablet') {
            height: $headerRowHeightDesktop;
        }

        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-end;

        a {
            box-sizing: border-box;
            display: block;
            height: $headerRowHeight;
            width: $headerRowHeight;
            transition-property: all;
            transition-duration: $headerTransitionDuration;
            transition-timing-function: $headerTransitionTimingFunction;
            @include media('>tablet') {
                height: $headerRowHeightDesktop;
                width: $headerRowHeightDesktop;
            }

            .fab {
                color: $gray;
                width: 100%;
                text-align: center;
                vertical-align: center;
                transition-property: all;
                transition-duration: $headerTransitionDuration;
                transition-timing-function: $headerTransitionTimingFunction;
                font-size: 0.6 * $headerRowHeight;
                line-height: 0.6 * $headerRowHeight;
                padding-top: 0.2 * $headerRowHeight;
                padding-bottom: 0.2 * $headerRowHeight;
                @include media('>tablet') {
                    font-size: 0.6 * $headerRowHeightDesktop;
                    line-height: 0.6 * $headerRowHeightDesktop;
                    padding-top: 0.2 * $headerRowHeightDesktop;
                    padding-bottom: 0.2 * $headerRowHeightDesktop;
                }
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    }
    &.shrinked {
        .mod_custom_header_social {
            height: $headerRowHeightShrinked;
            a {
                height: $headerRowHeightShrinked;
                width: $headerRowHeightShrinked;
                .fab {
                    font-size: 0.6 * $headerRowHeightShrinked;
                    line-height: 0.6 * $headerRowHeightShrinked;
                    padding-top: 0.2 * $headerRowHeightShrinked;
                    padding-bottom: 0.2 * $headerRowHeightShrinked;
                }
            }
        }
    }
}
