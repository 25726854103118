@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../abstracts/alerts';

//
// picnic variables
//

$picnic-white: $white;
$picnic-black: $gray;
$picnic-primary: $primaryColor !default;
$picnic-success: $green !default;
$picnic-warning: $orange !default;
$picnic-error: $red !default;
$picnic-dull: $gray !default;
$picnic-color-variation: 10% !default;
$picnic-transparency: .1 !default;

// Spaces
$picnic-separation: .6em !default;

// Shapes
$picnic-radius: 0 !default;
$picnic-border: 1px solid $grayLighter !default;
$picnic-shadow: none !default;
$picnic-overlay: rgba($picnic-black, $picnic-transparency);

// Transitions
$picnic-transition: all .2s ease !default;

// Plugins
$picnic-nav-position: absolute !default;

//
// end picnic variables
//

@import '~picnic/src/plugins/label/class';
@import '~picnic/src/plugins/input/class';
@import '~picnic/src/plugins/select/class';
@import '~picnic/src/plugins/button/class';

$formTextSize: 1rem;

input.submit, input.reset, button {
    box-sizing: border-box;
    line-height: 1.5;
    @include removeAppearance();
    @extend %button;
}

input.text, input.captcha, input[type="text"] {
    box-sizing: border-box;
    @include removeAppearance();
    @extend %input;
}

input[type="checkbox"] {
    @include removeAppearance();

    border: 1px solid $formInputBorderColor;
    @include border-radius(0);

    width: 1rem;
    height: 1rem;

    color: $formInputColor;
    background-color: $formInputBackgroundColor;

    &:checked {
        box-shadow: inset 0px 0px 0px 3px $white;
        background-color: $primaryColor;
    }

    &+label {
        margin-left: 0.5rem;
        vertical-align: text-bottom;
    }
}

textarea.textarea {
    box-sizing: border-box;
    min-height: 100px;
    @include removeAppearance();
    @extend %input;
}

select {
    box-sizing: border-box;
    @extend %select;
}

label {
    font-weight: bold;
}

form {
    p.error {
        @include p-error();
    }
    p.confirm {
        @include p-success();
    }
}

/*
.form-default {
    .formbody {
        fieldset {
            @include clearfix();
            display: block;
            zoom: 1;
            margin-top: 1em;
            legend {
                font-family: $altFontFamily;
                font-size: 1.1em;
                font-weight: bold;
                margin-bottom: 1em;
                color: $primaryColor;
            }
        }
        .control-group {
            clear: both;
            margin-bottom: 1em;
            label {
                display: block;
                width: 200px;
                float: left;
                text-align: right;
                font-weight: bold;
            }
            .controls {
                margin-left: 220px;
                span {
                    label {
                        display: inline;
                        float: none;
                        font-weight: normal;
                    }
                }
                .text, .textarea {
                    width: 75%;
                }
                .checkboxgroup {
                    span {
                        display: block;
                    }
                }
            }
        }
        .form-actions {
            padding-left: 220px;
        }
    }
}

.tableform {
    &.two_column {
        form {
            table {
                width: 100%;
                td {
                    padding-bottom: 1em;
                    label {
                        font-weight: bold;
                    }
                    &.col_0 {
                        width: 25%;
                    }
                    .checkbox_container {
                        label {
                            font-weight: normal;
                        }
                    }
                }
                .row_last {
                    td {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
*/
