@import '../../abstracts/variables';

.mod_eventreader {
    &.default {
        .layout_full {
            .content-wrapper {
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: 1fr;
                @include media('>=tablet') {
                    grid-template-columns: 1fr 2fr;
                }
                .image {
                    .image_container {
                        img {
                            width: 100%;
                        }
                    }
                }
                .content {
                    .info {
                        font-weight: bold;
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}
