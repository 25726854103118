@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

@mixin cut-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin ie7-inline-block {
    *display: inline;
    *zoom: 1;
}

@mixin fontAwesome {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
}

@mixin placeholder($color) {
    &:-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color: $color;
    }
}

@mixin removeAppearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
