.mod_eventmenu {
    ul {
        &.level_1 {
            display: flex;
            flex-direction: column;
            .year {
                > a, > .active {
                    font-size: 120%;
                }
            }
        }
        &.level_2 {
            display: flex;
            flex-direction: column;
        }
        .active {
            font-weight: bold;
        }
    }
}
