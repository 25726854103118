@import './variables';
@import './mixins';

@mixin mimetypes($width, $height) {
    .mime_icon {
        display: inline-block;
        width: $width;
        height: $height;

        &:before {
            @include fontAwesome();
            content: '\f15b';
            color: $primaryColor;
            font-size: $height;
            line-height: $height;
        }

        &.pdf {
            &:before {
                content: '\f1c1';
            }
        }

        &.doc, &.docx {
            &:before {
                content: '\f1c2';
            }
        }

        &.xls, &.xlsx {
            &:before {
                content: '\f1c3';
            }
        }

        &.ppt, &.pptx {
            &:before {
                content: '\f1c4';
            }
        }

        &.jpg, &.gif, &.png, &.jpeg, &.tiff {
            &:before {
                content: '\f1c5';
            }
        }
    }
}
