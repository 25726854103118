@mixin lists() {
    ul {
        margin-left: 1rem;
        list-style-type: square;
    }
    ol {
        margin-left: 1rem;
        list-style-type: decimal;
    }
}
