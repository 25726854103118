@import '../../abstracts/variables';

#header {
    .mod_custom_header_logo {
        grid-area: logo;
        padding: $headerPadding;
        height: $headerRowHeight;
        @include media('>tablet') {
            height: $headerRowHeightDesktop;
        }

        a {
            &.logo {
                box-sizing: content-box;
                display: block;
                transition-property: all;
                transition-duration: $headerTransitionDuration;
                transition-timing-function: $headerTransitionTimingFunction;

                height: $headerRowHeight;
                background-repeat: no-repeat;
                background-size: auto $headerRowHeight;
                background-image: url('components/header_logo/Logo BDF Pfad.svg');
                @include media('>minilogo') {
                    background-image: url('components/header_logo/Logo BDF Pfad.svg');
                }
                @include media('>tablet') {
                    background-size: auto $headerRowHeightDesktop;
                    height: $headerRowHeightDesktop;
                }
            }
        }
    }

    &.shrinked {
        .mod_custom_header_logo {
            height: $headerRowHeightShrinked;

            a {
                &.logo {
                    background-size: auto $headerRowHeightShrinked;
                    height: $headerRowHeightShrinked;
                }
            }
        }
    }
}
