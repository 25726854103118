@import '../../abstracts/variables';
@import '../../abstracts/mixins';

#footer {
    nav {
        background-color: $secondaryColor;

        a {
            color: $primaryColor;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @include media('>=tablet', '<desktop') {
                grid-template-columns: repeat(3, 1fr);
            }
            @include media('>=desktop') {
                grid-template-columns: repeat(4, 1fr);
            }

            li {
                padding: 0.3rem;

                &:before {
                    padding-right: 1rem;
                    @include fontAwesome();
                    content: '\f105';
                }

                &.active {
                    &:before {
                        content: '\f107';
                    }
                }

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                    &.active {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
