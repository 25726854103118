@import '../../abstracts/variables';

.klaro {
    font-size: inherit !important;
    &.cm-as-context-notice {
        padding: 0 !important;
        .context-notice {
            --border-radius: 0 !important;
            --light1: transparent !important;
            --light2: #{$grayLight} !important;
            --border-style: dashed !important;
            --border-width: 2px !important;
            padding: 0 !important;
        }
    }
}
