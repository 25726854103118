@import '../../abstracts/mimetypes';

.ce_download {
    @include mimetypes(1.5rem, 1.5rem);
    a {
        vertical-align: text-bottom;
        .size {
            display: none;
        }
    }
}
