// main colors
// -------------------------

$ltvRot: #147314;
//$ltvRotHell: lighten($ltvRot, 15%);
$ltvRotHell: #3e891e;
//$ltvBeige: #bcc4ad;
$ltvBeige: #61c061;
$ltvRotDunkel: #0c460c;

$ltvGrauHell: #f1f1f1;

// Grays
// -------------------------
$black: #000;
$grayDarker: #222;
$grayDark: #333;
$gray: #555;
$grayLight: #999;
$grayLighter: #ccc;
$grayLightest: #f1f1f1;
$white: #fff;

// Accent colors
// -------------------------
$blue: #049cdb;
$blueDark: #0064cd;
$green: #46a546;
$red: #9d261d;
$yellow: #ffc40d;
$orange: #f89406;
$pink: #c3325f;
$purple: #7a43b6;

// colors
// ------

$primaryColor: $ltvRot;
$secondaryColor: $ltvGrauHell;
$tertiaryColor: $ltvBeige;
$hoverColor: $ltvRotHell;

$linkColor: $primaryColor;
$linkColorHover: $linkColor;
//$alternateBodyBackgroundColor: $grayLightest;

$bodyBackgroundColor: lighten($grayLightest, 2%);
$bodyColor: $gray;

$mainBackgroundColor: $white;
$mainColor: $gray;

$headerBackgroundColor: $white;
$headerColor: $primaryColor;

$footerBackgroundColor: $primaryColor;
$footerColor: $white;

// scaffolding
// -------------------------

$headerRowHeight: 30px;
$headerRowHeightDesktop: 45px;
$headerBorderBottomWidth: 3px;

$headerRowHeightShrinked: 30px;
$headerTransition: all 500ms ease-in-out;
$headerPadding: 1rem;
$headerButtonMargin: 0.5rem;
$headerTransitionDuration: 500ms;
$headerTransitionTimingFunction: ease-in-out;
$headerButtonCount: 3;
$headerButtonCountFullnavigation: 2;
$headerSocialCount: 4;

$beforeVerticalPadding: 0.5rem;

$maxWidth: 1400px;
$landingCarouselMaxWidth: $maxWidth;
$landingCarouselMaxHeight: 490px;

$topNavHeight: 1.5rem;
$topNavVerticalPadding: 0.5rem;
$topNavHorizontalPadding: 0.5rem;
$topNavCaretSize: 5px;

$calcHeaderHeight: calc(#{$headerRowHeight} + 2 * #{$headerPadding} + #{$headerBorderBottomWidth});
$calcHeaderHeightDesktopWithoutFullNav: calc(#{$headerRowHeightDesktop} + 2 * #{$headerPadding} + #{$headerBorderBottomWidth});
$calcHeaderHeightDesktopWithFullNav: calc(#{$headerRowHeightDesktop} + 2 * #{$headerPadding} + #{$topNavHeight} + 2 * #{$topNavVerticalPadding});
$calcHeaderHeightShrinkedWithoutFullNav: calc(#{$headerRowHeightShrinked} + 2 * #{$headerPadding} + #{$headerBorderBottomWidth});
$calcHeaderHeightShrinkedWithFullNav: calc(#{$headerRowHeightShrinked} + 2 * #{$headerPadding} + #{$topNavHeight} + 2 * #{$topNavVerticalPadding});

// typography
// -------------------------

$sansFontFamily: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serifFontFamily: "Times New Roman", serif;
$monoFontFamily: Monaco, Menlo, Consolas, "Courier New", monospace;
$typedFontFamily: "Bitter";

$baseFontSize: 1em;
$baseFontFamily: $sansFontFamily;
$baseLineHeight: 1.5em;
$baseFontWeight: 400;
$altFontFamily: $typedFontFamily;
$altFontSize: 1em;
$altLineHeight: 1.5em;
$altFontWeight: 400;
$smallFontSize: 0.9em;
$miniFontSize: 0.7em;
$headingFontFamily: $sansFontFamily;
$headingFontWeight: 700;

// navigation
// -------------------------

// offcanvas button
$offcanvasBackgroundColor: $primaryColor;
$offcanvasBackgroundColorHover: $gray;

// megamenü
$topNavColor: $white;
$topNavBackgroundColor: $primaryColor;
$topNavHighlightColor: $primaryColor;
$topNavHighlightBackgroundColor: $secondaryColor;
$topNavActiveColor: $primaryColor;
$topNavFont: $typedFontFamily;
$topNavFontWeight: 400;
$topNavFontWeightBold: 700;
$topNavFontSize: $baseFontSize;
$topNavMegaBackgroundColor: $secondaryColor;
$topNavMegaColor: $bodyColor;
$topNavMegaDividerColor: $grayLight;

// forms
// -------------------------
$formInputColor: $gray;
$formInputBorderColor: $grayLight;
$formInputBackgroundColor: $grayLightest;
$formInputFocusBackgroundColor: $white;
$formInputPlaceholderColor: $grayLight;

$formSubmitColor: $white;
$formSubmitBackgroundColor: $primaryColor;
$formSubmitBorderColor: $primaryColor;
