@import '../../abstracts/variables';

.mod_breadcrumb {
    width: 100%;
    color: $primaryColor;
    a {
        color: $primaryColor;
        text-decoration: none;
    }
    ul {
        margin: 0 1rem;
        li {
            display: inline;
            &:after {
                content: " / "
            }
            &.first {
                display: none;
            }
            &.last {
                &:after {
                    content: "";
                }
            }
            &.active {
                font-weight: bold;
            }
        }
        li:after {
        }
        li.last:after {
        }
    }
}
