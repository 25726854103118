@import './../../abstracts/variables';

.mod_footer_bottom {
    background-color: $gray;
    .inside {
        box-sizing: border-box;
        margin: 0 auto;
        max-width: $maxWidth;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        @include media('>=tablet') {
            grid-template-columns: 1fr 1fr;
        }
        a {
            color: $white;
            font-weight: bold;
        }
        p {
            margin: 0;
            padding: 1rem;
            @include media('>=tablet') {
                &.links {
                    text-align: right;
                }
            }
        }
    }
}
