@import "../../abstracts/variables";

.mod_caroufredsel {
    position: relative;

    &.landing {
        grid-area: carousel;
        margin: -#{$beforeVerticalPadding} 0 -#{$beforeVerticalPadding} 0;

        .caroufredsel {
            max-height: $landingCarouselMaxHeight;
            overflow: hidden;

            figure {
                width: 100%;
                max-width: $landingCarouselMaxWidth;
                height: 100%;
                max-height: $landingCarouselMaxHeight;

                img {
                    width: 100%;
                    max-width: $landingCarouselMaxWidth;
                    height: 100%;
                    max-height: $landingCarouselMaxWidth;
                }
            }

            .ce_text {
                position: relative;

                div {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin: 0;
                    padding: 1%;
                    width: 98%;
                }

                &.oben {
                    div {
                        top: 0 !important;
                        bottom: inherit !important;
                    }
                }

                &.dunkel {
                    div {
                        background-color: rgba(0, 0, 0, 0.4);
                    }
                }

                &.sehrdunkel {
                    div {
                        background-color: rgba(0, 0, 0, 0.75);
                    }
                }

                &.dunkel, &.sehrdunkel {
                    div, h1, h2, h3, h4, h5, h6, p, a {
                        color: $white !important;
                    }
                }

                &.mitte {
                    div {
                        text-align: center;
                    }
                }

                &.rechts {
                    div {
                        text-align: right;
                    }
                }
            }
        }

        .caroufredsel_controls {
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            z-index: 0;

            .caroufredsel_pagi {
                float: right;
                $size: 0.8rem;

                a {
                    width: 1rem;
                    height: 1rem;
                    display: inline-block;
                    margin-left: $size;
                    background-color: $grayLighter;

                    span {
                        display: none;
                    }

                    border-radius: $size;
                }

                .selected {
                    background-color: $primaryColor;
                }
            }

            .caroufredsel_navi {
                float: right;

                a {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
