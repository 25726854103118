@import '../../abstracts/variables';

#header {
    nav {
        display: none;
        @include media('>=fullnavigation') {
            display: block;
        }

        grid-area: nav;

        box-sizing: border-box;
        background-color: $topNavBackgroundColor;
        width: 100%;
        overflow: visible !important;

        &.mega {

            a {
                display: block;
                text-decoration: none;
            }

            ul {
                margin: 0;

                &.level_1 {
                    box-sizing: border-box;
                    position: relative;
                    width: 100%;
                    margin: 0 auto;
                    padding: 0;
                    text-align: left;

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    > li {
                        list-style: none;
                        transition: 0.5s;

                        > a {
                            font-weight: $topNavFontWeightBold;
                            color: $topNavColor;
                            height: $topNavHeight;
                            line-height: $topNavHeight;
                            padding: $topNavVerticalPadding 25px;
                        }

                        &.submenu {
                            > a {
                                height: $topNavHeight;
                                line-height: $topNavHeight;
                                padding: $topNavVerticalPadding $topNavHorizontalPadding $topNavVerticalPadding calc(#{$topNavHorizontalPadding} + 3 * #{$topNavCaretSize});
                            }
                            > a:after {
                                position: relative;
                                float: right;
                                content: '';
                                margin-left: calc(2 * #{$topNavCaretSize});
                                margin-top: calc((#{$topNavHeight} - #{$topNavCaretSize}) / 2);
                                border-left: $topNavCaretSize solid transparent;
                                border-right: $topNavCaretSize solid transparent;
                                border-top: $topNavCaretSize solid $topNavColor;
                                border-bottom: $topNavCaretSize solid transparent;
                            }

                            &:hover > a:after {
                                margin-top: $topNavCaretSize;
                                border-top: $topNavCaretSize solid transparent;
                                border-bottom: $topNavCaretSize solid $topNavHighlightColor;
                            }
                        }

                        &:hover {
                            background-color: $topNavMegaBackgroundColor;

                            > a {
                                color: $topNavHighlightColor;
                            }

                            ul.level_2 {
                                display: grid;
                            }
                        }
                    }
                }

                &.level_2 {
                    box-sizing: border-box;
                    position: absolute;
                    display: none;
                    padding: 1rem 0;
                    z-index: 1;
                    background-color: $topNavMegaBackgroundColor;

                    &.align-right {
                        right: 0;
                    }

                    $columnWidth: 250px;

                    &.items-1 {
                        grid-template-columns: repeat(1, minmax($columnWidth, 1fr));
                        width: calc(#{$columnWidth} + 2rem);
                    }

                    &.items-2 {
                        grid-template-columns: repeat(2, minmax($columnWidth, 1fr));
                        width: calc(2 * #{$columnWidth} + 3rem)
                    }

                    &.items-3 {
                        grid-template-columns: repeat(3, minmax($columnWidth, 1fr));
                        width: calc(3 * #{$columnWidth} + 4rem)
                    }

                    &.items-4 {
                        grid-template-columns: repeat(3, minmax($columnWidth, 1fr));
                        width: calc(3 * #{$columnWidth} + 4rem)
                    }

                    &.items-5 {
                        grid-template-columns: repeat(3, minmax($columnWidth, 1fr));
                        width: calc(3 * #{$columnWidth} + 4rem)
                    }

                    &.items-6 {
                        grid-template-columns: repeat(3, minmax($columnWidth, 1fr));
                        width: calc(3 * #{$columnWidth} + 4rem)
                    }

                    & > li {
                        padding-left: 1rem;
                        padding-right: 1rem;

                        &.col-1 {
                            grid-column-start: 1;
                        }

                        &.col-2 {
                            grid-column-start: 2;
                        }

                        &.col-3 {
                            grid-column-start: 3;
                        }

                        &.span-2 {
                            grid-row-end: span 2
                        }

                        &:not(.last), &.border-right {
//                            border-right: 1px dotted $topNavMegaDividerColor;
                        }

                        &.no-border {
                            border-right: 0;
                        }

                        > a {
                            color: $topNavMegaColor;
                            font-weight: bold;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.level_3 {
                    & > li > a {
                        color: $topNavMegaColor;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &.level_4 {
                    padding-left: 1rem;
                    font-size: $smallFontSize;
                    margin-bottom: 0;

                    & > li > a {
                        color: $topNavMegaColor;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
