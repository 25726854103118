.ce_vimeo, .ce_youtube {
    [data-type="placeholder"] {
        max-width: 100% !important;
        height: 100% !important;
        box-sizing: border-box;
    }
    .video_container {
        video {
            max-width:100%;
            height:auto;
        }
        .responsive {
            position:relative;
            height:0;

            &.ratio-169 {
                padding-bottom:56.25%;
            }
            &.ratio-1610 {
                padding-bottom:62.5%;
            }
            &.ratio-219 {
                padding-bottom:42.8571%;
            }
            &.ratio-43 {
                padding-bottom:75%;
            }
            &.ratio-32 {
                padding-bottom:66.6666%;
            }

            iframe, [data-type="placeholder"] {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                border:0;
            }
        }
    }
}
