@import '../../abstracts/variables';
@import '../../abstracts/lists';

.mod_eventlist {
    .layout_upcoming {
        display: grid;
        grid-template-columns: 90px 1fr;
        time {
            overflow: hidden;
            white-space: nowrap;
        }

        a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &.default {
        .header {
            margin: 0 -1rem;
            background-color: $grayLight;
            color: white;
            padding: 1rem;
            font-size: 1rem;
            .day {
                vertical-align: text-bottom;
                padding-right: 0.3rem;
            }
            .date {
                font-weight: bold;
                font-size: 1.5rem;
            }
        }
        .layout_list {
            margin: 0 -1rem;
            padding: 1rem;
            &.even {
                background-color: $white;
            }
            &.odd {
                background-color: $grayLightest;
            }
            .time {
                font-weight: bold;
                color: $primaryColor;
            }
            .content-wrapper {
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: 1fr;
                @include media('>=tablet') {
                    grid-template-columns: 2fr 1fr;
                }
                .content {
                    word-break: break-word;
                    grid-row-start: 2;
                    @include media('>=tablet') {
                        grid-row-start: 1;
                    }
                }
                .image {
                    grid-row-start: 1;
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 75%;
                    .image_container {
                        position: absolute;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .placeholder_container {
                        border: 2px solid $grayLightest;
                        position: absolute;
                        width: 100%;
                        padding-bottom: 75%;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-image: url('components/mod_eventlist/empty.svg');
                        overflow: hidden;
                    }
                }
            }
        }
    }
    @include lists();
}
