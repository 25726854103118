@import '../../abstracts/variables';
@import '../../abstracts/mixins';

.pagination {
    text-align: center;
    ul {
        margin: 0;
    }
    li {
        list-style: none;
        display: inline-block;
        @include ie7-inline-block;
        margin-bottom: 0.5rem;
        // TODO: span.current is for contao 3, can be removed after migration
        a, span.current, strong.active {
            text-decoration: none;
            padding: 0.4em 0.7em;
            border: 1px solid $secondaryColor;
            color: $primaryColor;
            display: inline-block;
            @include ie7-inline-block;
            min-width: 1.4em;
        }
        span.current, strong.active {
            font-weight: bold;
            background-color: lighten($secondaryColor, 10%);
        }
        a {
            background-color: $secondaryColor;
            &:hover {
                background-color: lighten($secondaryColor, 10%);
                @include transition(1s background ease-out);
            }
        }
        &.first {
            a {
                &:after {
                    content: "↞"
                }
            }
        }
        &.previous {
            a {
                &:after {
                    content: "←";
                }
            }
        }
        &.next {
            a {
                &:after {
                    content: "→"
                }
            }
        }
        &.last {
            a {
                &:after {
                    content: "↠";
                }
            }
        }
    }
}
