@import '../abstracts/variables';

body {
    font-family: $baseFontFamily;
    font-size: $baseFontSize;
    font-weight: $baseFontWeight;
    line-height: $baseLineHeight;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $headingFontFamily;
    font-weight: $headingFontWeight;
    line-height: $baseLineHeight - 0.1em;
    margin-top: 0;
    margin-bottom: 0.2em;
    a {
        text-decoration: none;
    }
}

#wrapper {
    h1, h2, h3, h4, h5, h6 {
        color: $primaryColor;
    }
}

a {
    color: $linkColor;
    text-decoration: none;
    &:hover {
        color: $linkColorHover;
        text-decoration: underline;
    }
}

*:focus {
    outline: none;
}

.small {
    font-size: $smallFontSize;
}

.mini {
    font-size: $miniFontSize;
}
