@import './../../abstracts/variables';

#footer {
    .mod_customnav {
        background-color: $footerBackgroundColor;
        ul {
            box-sizing: border-box;
            margin: 0 auto;
            max-width: $maxWidth;
            padding: 1rem;
            color: $white;
            a {
                color: $white;
                text-decoration: none;
            }
            li {
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}
