@import '../../abstracts/mimetypes';

.ce_downloads {
    li {
        min-height: 1.5rem;
        margin-bottom: 0.3rem;
        line-height: 1.5rem;
        @include mimetypes(1.5rem, 1.5rem);
        a {
            vertical-align: text-bottom;
            .size {
                display: none;
            }
        }
    }
}
