@import './mixins';
@import './variables';

@mixin table-bordered($border, $radius) {
    *border-collapse: collapse; /* IE7 and lower */
    border-spacing: 0;
    border-collapse: separate;
    border: $border;
    @include border-radius($radius);
    tr {
        &:first-child {
            > th:first-child, td:first-child {
                @include border-radius($radius 0 0 0);
            }
            > th:last-child, td:last-child {
                @include border-radius(0 $radius 0 0);
            }
        }
    }
    thead ~ tbody {
        tr {
            &:first-child {
                > td:first-child, > td:last-child {
                    @include border-radius(0 0 0 0);
                }
            }
        }
    }
    tr {
        &:last-child {
            > td:first-child {
                @include border-radius(0 0 0 $radius);
            }
            > td:last-child {
                @include border-radius(0 0 $radius 0);
            }
        }
    }
}

@mixin table-grid($border) {
    *border-collapse: collapse; /* IE7 and lower */
    border-spacing: 0;
    border-collapse: collapse;
    thead {
        tr:first-child {
            th {
                border: none;
            }
        }
        tr {
            th {
                border-left: $border;
                border-top: $border;
            }
            th:first-child {
                border-left: none;
            }
        }
    }
    tbody {
        tr {
            td {
                border-left: $border;
                border-top: $border;
            }
            td:first-child {
                border-left: none;
            }
        }
    }
}

@mixin table-striped($bgColorOdd, $bgColorEven) {
    tbody {
        tr:nth-child(odd) {
            background-color: $bgColorOdd;
        }
        tr:nth-child(even) {
            background-color: $bgColorEven;
        }
    }
}

@mixin table-hovered($background) {
    tbody tr:hover {
        background: $background;
        @include transition(all 0.1s ease-in-out);
    }
}

@mixin default-inline-table {
    table {
        margin: 0;
        *border-collapse: collapse; /* IE7 and lower */
        border-spacing: 0;
        border-collapse: collapse;
        th, td {
            padding: 0.3rem 0.5rem;
            text-align: left;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}


@mixin default-inline-table-bordered($borderColor) {
    &.bordered {
        table {
            border: 1px solid $borderColor;
        }
    }
}

@mixin default-inline-table-grid($borderColor) {
    &.grid {
        table {
            th, td {
                border: 1px solid $borderColor;
            }
        }
    }
}

@mixin default-inline-table-striped($bgColorOdd, $bgColorEven) {
    &.striped {
        table {
            @include table-striped($bgColorOdd, $bgColorEven);
        }
    }
}

@mixin default-inline-table-sizing {
    &.full {
        table {
            width: 100%;
        }
    }
    &.half {
        table {
            width: 50%;
        }
    }
    &.quarter {
        table {
            width: 25%;
        }
    }
}


/* remove from here if not needed */

@mixin default-table {
    width: 100%;
    margin-top: 10px;
    th, td {
        padding: 10px;
    }
    thead {
        th {
            text-align: left;
        }
    }
    @include table-bordered(0, 0);
    @include table-striped($grayLightest, $white);
    @include table-hovered($grayLighter);
}

@mixin default-table-lr {
    thead {
        th:last-child {
            text-align: right;
        }
    }
    tbody {
        tr {
            td:last-child {
                text-align: right;
            }
        }
    }
}

