@import '../../abstracts/variables';
@import '../../abstracts/tables';

.mod_calendar {
    overflow-x: auto;
    table.calendar {
        $border: 1px solid $grayLighter;
        min-width: 200%;
        @include media('>=tablet') {
            min-width: 100%;
        }
        @include table-bordered($border, 0);
        @include table-grid($border);
        @include table-hovered($grayLightest);
        border-collapse: collapse;
        border-spacing: 0;
        th {
            width: calc(100% / 7);
        }
        th, td {
            padding: 4px;
        }
        td.today {
            background-color: lighten($blue, 40%);
        }
        td {
            height: 100px;
            font-size: 0.9em;
            .event {
                font-size: 0.8em;
                color: $white;
                background-color: $grayLight;
                word-break: break-all;
                padding: 2px 4px;
                margin-top: 2px;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    color: $white;
                }
            }
        }
    }
}
