@import "../../abstracts/variables";
@import "../../abstracts/mimetypes";
@import "../../abstracts/lists";

.mod_newslist {
    .teaser {
        word-break: break-word;
    }
}

@mixin category_list($borderSize) {
    .category_list {
        position: relative;
        width: 100%;
        padding-bottom: 75%; // force 4:3 see https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css
        background-color: $grayLightest;
        @if ($borderSize > 0) {
            background-size: calc(100% - #{2 * $borderSize}) calc(100% - #{2 * $borderSize});
            background-position: $borderSize $borderSize;
        } @else {
            background-size: 100% 100%;
            background-position: 0 0;
        }
        background-repeat: no-repeat;
        background-image: url('components/mod_newslist/empty.svg');
        overflow: hidden;
        ul {
            list-style-type: none;
            position: absolute;
            margin: 1rem;
            bottom: 0;
            right: 0;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: bold;
            color: $primaryColor;
            li {
                text-align: right;
            }
        }
    }
}

.mod_newsarchive, .mod_newslist, .mod_newsreader {
    .layout_simple {
        display: grid;
        grid-template-columns: 90px 1fr;
        time {
            overflow: hidden;
            white-space: nowrap;
        }

        a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.default {
        h1 {
            font-size: 1.6667rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.3333rem;
        }

        @include category_list(2px);

        .layout_latest {
            &:not(:first-child) {
                margin-top: 1rem;
            }
            display: grid;
            grid-gap: 1rem;
            grid-template-areas:
            "image"
            "headline"
            "teaser"
            "footer";
            grid-template-columns: 1fr;
            @include media('>phone') {
                grid-template-areas:
                    "image headline"
                    "image teaser"
                    "image footer";
                grid-template-columns: 1fr 2fr;
            }
            grid-template-rows: [headline] fit-content(100%) [teaser] max-content [footer] auto;
            border-bottom: 1px solid $grayLightest;
            &:last-child , &.last {
                border-bottom: none;
            }
        }

        .layout_full {
            &:not(:first-child) {
                margin-top: 1rem;
            }
            display: grid;
            grid-gap: 1rem;
            grid-template-areas:
            "image"
            "headline"
            "teaser"
            "text"
            "footer";
            &.has-subheadline {
                grid-template-areas:
                "image"
                "headline"
                "subheadline"
                "teaser"
                "text"
                "footer";
            }
            grid-template-columns: 1fr;
            @include media('>phone') {
                grid-template-areas:
                    "image headline"
                    "image teaser"
                    "image text"
                    "image footer";
                &.has-subheadline {
                    grid-template-areas:
                        "image headline"
                        "image subheadline"
                        "image teaser"
                        "image text"
                        "image footer";
                }
                grid-template-columns: 1fr 2fr;
            }
            grid-template-rows: [headline] fit-content(100%) [teaser text footer] auto;
            &.has-subheadline {
                grid-template-rows: [headline subheadline] fit-content(100%) [teaser text footer] auto;
            }
        }

        .layout_latest, .layout_full {
            .image_container, .category_container {
                grid-area: image;
                overflow-x: hidden;
                margin-bottom: 1rem;
            }

            .image_container {
                img {
                    width: 100%;
                }
                figcaption {
                    font-size: small;
                }
            }

            .news_headline {
                grid-area: headline;
                overflow-x: hidden;
                display: table;
            }

            .news_subheadline {
                grid-area: subheadline;
                overflow-x: hidden;
                display: table;
            }

            .teaser {
                grid-area: teaser;
                overflow-x: hidden;
            }

            .text {
                grid-area: text;
                overflow-x: hidden;
            }

            .footer {
                grid-area: footer;
                .more {
                    &:before {
                        content: "» ";
                    }
                    overflow-x: hidden;

                    text-align: right;
                    text-transform: lowercase;
                }

                .info {
                    overflow-x: hidden;

                    font-size: small;
                    text-align: right;
                    font-style: italic;
                }
            }
        }

        .layout_full:first-child {
            .image_container {
                &.float_above {
                    margin-top: 1.5em;
                }
            }
        }
    }

    .enclosure {
        p {
            min-height: 1.5rem;
            margin-bottom: 0.3rem;
            line-height: 1.5rem;
            @include mimetypes(1.5rem, 1.5rem);
            a {
                vertical-align: text-bottom;
                .size {
                    display: none;
                }
            }
        }
    }

    @include lists();
}

.mod_newslist {
    &.landing {
        $newsTilesBackgroundColor: $grayLightest;
        $margin: 1rem;
        $padding: 1rem;
        $headlineFontSize: 1.5rem;
        $headlineLineHeight: 1.7rem;
        $textFontSize: 1rem;
        $textLineHeight: 1.1rem;
        $teaserLines: 6;

        display: flex;
        flex-wrap: wrap;
        margin-right: -$margin !important;
        box-sizing: border-box;

        .layout_latest {
            margin-right: 1rem;
            flex: 0 1 auto;
            box-sizing: border-box;
            padding: 1rem;

            width: calc(100% - #{$margin});
            max-width: calc(100% - #{$margin});
            @include media('>phone', '<=tablet') {
                width:  calc(50% - #{$margin});
                max-width: calc(50% - #{$margin});
            }
            @include media('>tablet') {
                width:  calc(50% - #{$margin});
                max-width: calc(50% - #{$margin});
            }
            overflow: hidden;

            background-color: $newsTilesBackgroundColor;

            border: none;
            margin-bottom: 2rem;

            figure {
                width: calc(100% + 2 * #{$margin});
                margin-left: -$padding;
                margin-top: -$padding;
                margin-right: -$padding;
                display: block;
                box-sizing: border-box;
                border: 2px solid $newsTilesBackgroundColor;

                a, img {
                    width: 100%;
                    display: block;
                    box-sizing: border-box;
                }
            }
            .category_container {
                width: calc(100% + 2 * #{$margin});
                margin-left: -$padding;
                margin-top: -$padding;
                margin-right: -$padding;
                display: block;
                box-sizing: border-box;
                border: 2px solid $newsTilesBackgroundColor;
            }

            @include category_list(0);

            .news-content {
                width: 100%;
                max-width: 100%;

                h2 {
                    width: 100%;
                    max-width: 100%;
                    height: $headlineLineHeight;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: $headlineFontSize;
                    line-height: $headlineLineHeight;
                }

                .info, .teaser, .more {
                    width: 100%;
                    max-width: 100%;
                    font-size: $textFontSize;
                    line-height: $textLineHeight
                }

                .info {
                    text-align: left;
                    overflow: hidden;
                    text-overflow: clip;
                    white-space: nowrap;
                }

                .teaser {
                    overflow: hidden;
                    height: $teaserLines * $textLineHeight;
                    max-height: $teaserLines * $textLineHeight;
                }

                .more {
                    text-align: right;
                    overflow: hidden;
                    text-overflow: clip;
                    white-space: nowrap;
                    &:before {
                        content: "» ";
                    }
                    text-transform: lowercase;
                    margin-top: 0.5rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}
