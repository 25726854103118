.mod_newsmenu {
    margin-bottom: 1rem;
    select {
        width: 100%;
        @include media('>=tablet', '<desktop') {
            width: 33.33%;
        }
        @include media('>=desktop') {
            width: 25%;
        }
    }
}
